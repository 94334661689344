<script>
import { defineElement } from '@vueform/vueform';
import Calendar from 'primevue/calendar';

export default defineElement({
  name: 'VueformCalendarElement',
  setup(props, { element }) {},
  props: {
    customIcon: {
      type: String,
      required: false,
      default: 'pi pi-calendar',
    },
    disableCal: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxCalDate: {
      type: Date,
      required: false,
      default: new Date('02-02-06'),
    },
    minCalDate: {
      type: Date,
      required: false,
      default: new Date('01-01-1924'),
    },
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <div class="relative my-1">
        <label
          class="vf-cal-label bg-light-50 dark:bg-dark-800 text-black dark:text-white w-fit"
        >
          {{ $t(`label.dateOfBirth`) }}
        </label>
        <Calendar
          v-model="value"
          :maxDate="maxCalDate"
          :minDate="minCalDate"
          dateFormat="dd-mm-yy"
          id="calendar"
          appendTo=".p-calendar"
          :showIcon="true"
          :placeholder="$t('placeholder.dateOfBirth')"
          :icon="customIcon"
          :pt="{ input: ({ props }) => ({ class: 'pl-2.75' }) }"
          :disabled="disableCal"
        />
      </div>
    </template>
  </ElementLayout>
</template>

<style scoped>
.vf-cal-label {
  margin-left: 14px;
  position: absolute;
  top: -11px;
  font-size: 11px;
}
</style>
<style lang="scss">
.p-calendar input.p-component::placeholder {
  font-size: 16px;
}

.vf-element-layout div.relative button.p-datepicker-trigger {
  right: 0px; //parent is relative so making adjustments
}

.vf-element-layout div.relative .p-datepicker {
  top: 42px !important;
  left: -15px !important;
}
</style>

<script>
import { defineElement } from '@vueform/vueform';
export default defineElement({
  name: 'VueformPasswordElement',
  setup(props, { element }) {},
  props: {
    displayFeedback: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <DesignSystemFormPassword
        v-model="value"
        ref="input"
        label="Password"
        labelClass="vueform-float-label"
        class="w-full"
        :feedback="displayFeedback"
      />
    </template>
  </ElementLayout>
</template>

import en from '@vueform/vueform/locales/en'
import vueform from '@vueform/vueform/dist/vueform'
import { defineConfig } from '@vueform/vueform'

import '@vueform/vueform/dist/vueform.css';
import VueformRadioElement from './components/design-system/form/VueformRadioElement.vue';
import VueformPasswordElement from './components/design-system/form/VueformPasswordElement.vue';
import VueformCalendarElement from './components/design-system/form/VueformCalendarElement.vue';

export default defineConfig({
  elements: [
    VueformPasswordElement, VueformCalendarElement, VueformRadioElement,
  ],
  theme: vueform,
  locales: { en },
  locale: 'en',
  

})